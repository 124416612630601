<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title card-header-tab  card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('generic.lang_createReservation') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right actions-icon-btn">
            <strong
                v-if="Number(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined  && (this.form.resType !== undefined && this.form.resType !== '') && (Array.isArray(this.tables))">
              {{ this.tables.join(' , ') }}
            </strong>
            <v-btn
                v-if="Number(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined  && (this.form.resType !== undefined && this.form.resType !== '') && (this.selectedType && this.selectedType.skipTable !== 1)"
                @click="openTablesDialog" outlined color="primary">
              <v-icon x-large>add</v-icon>
            </v-btn>
            <v-btn :disabled="loading || loadData || !valid" :loading="loading" class="elevation-0"
                   color="primary"
                   fab small @click="saveReservation">
              <v-icon>check</v-icon>
            </v-btn>

            <v-btn v-if="this.show_close" class="elevation-0"
                   color="error"
                   fab small @click="cancel">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-divider class="pa-0 ma-0"/>

      <v-card-text class="pb-0 pt-8">
        <v-form ref="form" lazy-validation v-model="valid">
          <v-row>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12"
                   sm="12">
              <v-select @change="loadItems($event)" v-model="form.resType" :disabled="this.loadData"
                        :items="this.types"
                        :label="this.$t('generic.lang_constReservationType')" :loading="this.loadData"
                        :rules="[ v => !!v]"
                        dense
                        item-text="name"
                        item-value="uuid"
                        outlined/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <BaseDateInput v-model="form.date" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData || (this.selectedType && this.selectedType.date != null)"
                            :label="this.$t('generic.lang_date')"
                            :min="this.current_date"
                            :rules="[ v => (this.selectedType && this.selectedType.disableRequiredFields)||!!v]"
                            dense outlined type="date"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.time" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                :rules="[  v => !!v && validTime || $t('reservation.start_date_bigger_end')]"
                                dense :label="$t('generic.lang_time')" outlined
                                v-bind="attrs"
                                v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="menu"
                  v-model="form.time"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('generic.lang_cancel') }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(form.time)">
                    {{ $t('generic.lang_apply') }}
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>

            <!-- set reservation end time -->
            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" v-if="this.can_set_end_time">

              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.end_at"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.end_at" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                :rules="[  v => !!v && validTime || $t('reservation.end_date_great_start')]"
                                dense :label="$t('generic.lang_endsAt')" outlined
                                v-bind="attrs"
                                v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="form.end_at"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    {{ $t('generic.lang_cancel') }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(form.end_at)">
                    {{ $t('generic.lang_apply') }}
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-col>

            <!-- search customer - REPLACED WITH CUSTOM COMPONENT -->
            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <!-- Custom Keyboard Friendly Autocomplete -->
              <div v-if="showSelect" class="keyboard-friendly-autocomplete">
                <!-- Main input field -->
                <v-text-field
                  ref="customerInput"
                  v-model="customerSearchText"
                  :label="$t('generic.lang_name')"
                  :rules="[ v => !!v]"
                  :disabled="loadData"
                  :loading="isLoadingCustomers"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="handleCustomerInputFocus"
                  @input="handleCustomerInputChange"
                  @click="toggleCustomerDropdown"
                  outlined
                  dense
                  clearable
                >
                  <!-- Add loading indicator -->
                  <template v-slot:append>
                    <v-progress-circular
                      v-if="isLoadingCustomers"
                      indeterminate
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                    <v-icon v-else @click="toggleCustomerDropdown">
                      {{ showCustomerDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </template>
                </v-text-field>
                
                <!-- Dropdown menu for suggestions -->
                <v-card
                  v-show="showCustomerDropdown"
                  class="keyboard-autocomplete-dropdown"
                  outlined
                  elevation="4"
                >
                  <!-- Add New Customer option -->
                  <div v-if="show" class="pa-3">
                    <v-list-item class="mt-0 pt-0 mb-0 pb-0">
                      <v-list-item-title>
                        {{ $t('datatables.lang_search') }}
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-btn icon @click="addCustomer">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                  
                  <!-- No data message -->
                  <v-list-item v-if="filteredCustomers.length === 0">
                    <v-list-item-content>
                      <div class="text-center">
                        <span v-if="isLoadingCustomers" class="mx-auto">{{ $t('generic.loading_from_server') }}</span>
                        <span v-if="!isLoadingCustomers" class="mx-auto">{{ $t('generic.lang_no_data') }}</span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  
                  <!-- Results list -->
                  <template v-else>
                    <v-list dense>
                      <v-list-item
                        v-for="(customer, index) in filteredCustomers"
                        :key="index"
                        @click="selectCustomer(customer)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="primary--text">{{ customer.full_name }} </span> 
                            <span class="ml-2 small">
                              {{
                                (`${customer.street || ''}${customer.streetNumber || ''}${customer.zip || ''}`).length > 0 ? 
                                `  ${customer.street || ''}  ${customer.streetNumber || ''}  ${customer.zip || ''}` : ''
                              }}
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-card>
              </div>
              
              <v-text-field v-if="!showSelect" v-model="form.name" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="this.loadData" :rules="[ v => !!v]" dense
                            :label="$t('generic.lang_name')" outlined
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.email" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :label="this.$t('generic.lang_emailAdresse')"
                            :rules="[ v => (this.selectedType && this.selectedType.disableRequiredFields)||!!v]"
                            dense
                            outlined type="email"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col v-if="!!this.form.customer || this.form.name.length > 0" class="pt-0 pb-0 mt-0 mb-0" cols="12"
                   sm="12">
              <v-textarea v-model="form.customer_note" :data-layout="KEYBOARD.KEYSETS.NORMAL" dense
                          :label="$t('generic.lang_customerNote')" outlined @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.phone" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :label="this.$t('generic.lang_telefone')"
                            :rules="[ v => (this.selectedType && this.selectedType.disableRequiredFields)||!!v]"
                            dense outlined type="tel"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.person" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :label="this.$t('generic.lang_noOfPersons')"
                            :min="1"
                            :rules="[ v => (this.selectedType && this.selectedType.disableRequiredFields)||!!v]"
                            :value="1"
                            dense
                            outlined type="number"
                            @focus="showTouchKeyboard"/>
            </v-col>


            <!--start: list available items-->
            <v-col v-if="form.resType && this.selectedType && this.selectedType.skipOrders !== 1" cols="12">
              <v-expansion-panels class="elevation-0">
                <v-expansion-panel class="elevation-0">
                  <v-progress-linear indeterminate color="primary" v-if="this.items_loader"/>
                  <v-expansion-panel-header color="success" class="white--text">{{
                      this.$t("erp.lang_items")
                    }}
                  </v-expansion-panel-header>


                  <v-expansion-panel-content v-if="Array.isArray(this.items)" class="elevation-0 pt-4">
                    <div class="w-100 text-right">
                      <v-spacer/>
                      <v-btn @click="pushItem" class="mx-auto" color="primary" outlined>
                        {{ $t('generic.lang_add') }}
                      </v-btn>
                    </div>

                    <v-form lazy-validation ref="items_form">
                      <v-data-table v-if="Array.isArray(this.form.items) && this.form.items.length > 0"
                                    :items="this.form.items" :headers="this.items_datatable_headers">
                        <template v-slot:item.menu_type="{item}">
                          <v-select :loading="menu_types_loader" :disabled="menu_types_loader" @change="$forceUpdate()"
                                    :rules="[v => !!v || $t('generic.lang_select_type')]" v-model="item.menu_type" dense
                                    outlined
                                    hide-details :items="menuTypes"
                                    item-value="uuid"
                                    item-text="menuTypeName"/>
                        </template>
                        <template v-slot:item.id="{item}">
                          <v-select :disabled="!item.menu_type" @change="$forceUpdate()"
                                    :rules="[v => !!v || $t('generic.lang_select_an_item')]" v-model="item.id" dense
                                    outlined
                                    hide-details :items="available_items(item.id , item.menu_type)"
                                    item-value="id"
                                    item-text="name"/>
                        </template>

                        <template v-slot:item.quantity="{item}">
                          <v-text-field :disabled="!item.menu_type" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        @focus="showTouchKeyboard" :min="1"
                                        type="number" v-model="item.quantity" dense outlined hide-details
                                        item-value="id"
                                        item-text="name"/>
                        </template>

                        <template v-slot:item.price="{item}">
                          {{ itemPrice(item.id) | currency }}
                        </template>


                        <template v-slot:item.total="{item}">
                          {{ (itemPrice(item.id) * item.quantity) | currency }}
                        </template>


                        <template v-slot:item.actions="{item}">
                          <v-btn @click="removeItem(item)" icon color="error">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-col>
            <!--end: list available items-->

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-textarea v-model="form.note" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                          :label="this.$t('generic.lang_notes')" outlined
                          @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-textarea v-model="form.internal_note" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                          :label="this.$t('generic.lang_internalNotes')"
                          outlined
                          @focus="showTouchKeyboard"/>
            </v-col>


            <v-col cols="12">
              <v-text-field id="file" v-model="form.file_to_upload" dense outlined type="file"/>
            </v-col>
            <v-col v-if="this.history && this.history.length > 0">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('reservation.lang_reservationsHistory') }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                      <div v-for="res in this.history" :key="res.uuid" class="vertical-timeline-element">
                        <div><span class="vertical-timeline-element-icon bounce-in"><i
                            class="badge badge-dot badge-dot-xl badge-primary"> </i></span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4
                                class="timeline-title">
                              <font-awesome-icon :icon="['fal', 'hashtag']" class="text-muted actions  mx-0" size="2x"
                                                 style="font-size: 16px"
                              />
                              {{ res.code }}
                            </h4>
                            <p>
                              <strong class="text-muted">
                                <font-awesome-icon :icon="['fal', 'calendar-alt']" class="text-muted actions  mx-0"
                                                   size="2x"
                                                   style="font-size: 16px"
                                />
                                {{ new Date(res.date).toDateString() }}
                              </strong>
                              <br/>
                              <v-chip v-if="res.canceled == true" color="error" x-small>
                                {{ $t('generic.lang_canceled') }}
                              </v-chip>
                              <v-chip v-else-if="res.confirmed == true" color="success" x-small>
                                {{ $t('generic.lang_confirmed') }}
                              </v-chip>

                              <v-chip v-else-if="res.confirmed == false" color="warning" x-small>
                                {{ $t('generic.lang_unconfirmed') }}
                              </v-chip>
                            </p>
                            <span
                                class="vertical-timeline-element-date">{{ res.time }}</span>
                          </div>
                        </div>
                      </div>


                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <v-col
                v-if="!(Number(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined && (this.form.resType !== undefined && this.form.resType !== '') )||(this.selectedType && this.selectedType.disableRequiredFields)"
                cols="12">
              <v-progress-linear v-if="this.rooms_loader" color="primary" indeterminate>

              </v-progress-linear>
              <v-alert class="mx-auto" type="info">
                {{ $t('generic.lang_reserverationMissingDateTime') }}
              </v-alert>
            </v-col>
            <v-col
                v-if="Number(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined  && (this.form.resType !== undefined && this.form.resType !== '')"
                class=""
                cols="12">

              <!--dialog to select tables-->
              <reservation-table-selector v-bind:end_at="this.form.end_at" @selected="selected"
                                          v-bind:date="this.form.date"
                                          v-bind:persons="Number(this.form.person)"
                                          v-bind:reservation_type="this.form.resType" v-bind:time="this.form.time"
                                          v-model="tables_dialog" v-bind:dialog="this.tables_dialog"
                                          @closed="form.selected=$event"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- loader dialog -->
      <v-dialog
          v-model="customer_loader"
          hide-overlay
          persistent
          width="300"

      >
        <v-card
            color="primary"
            dark
        >
          <v-card-text>
            {{ this.$t('datatables.lang_Processing') }}
            <v-progress-linear
                class="mb-0"
                color="white"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Template from "../../settings/customerdisplay/Template";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarAlt, faHashtag,} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import mixin from "@/mixins/KeyboardMixIns";
import ReservationTableSelector from "@/components/reservation/reservations/ReservationTableSelector";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

library.add(faCalendarAlt, faHashtag);


export default {
  name: "CreateReservation",
  props: {
    default_date: String,
    default_time: String,
    show_close: {
      type: Boolean,
      value: false
    }
  },
  mixins: [mixin],
  components: {ReservationTableSelector, Template, 'font-awesome-icon': FontAwesomeIcon, BaseDateInput},
  computed: {
    validTime() {
      if (this.can_set_end_time) {
        if (this.form.time && this.form.end_at) {
          return this.hmToSecondsOnly(this.form.time) < this.hmToSecondsOnly(this.form.end_at)
        }
      }
      return true;
    },
    can_set_end_time() {
      if (Array.isArray(this.types) && this.form.resType) {
        //find selected type
        let index = this.types.indexOf(this.types.find((type) => type.uuid === this.form.resType));
        if (index > -1)
          return this.types[index].can_set_end_time === 1;
      }
      return false;
    },
    itemPrice() {
      const self = this;

      return (id) => {
        //let's find the item
        if (Array.isArray(self.items)) {
          let res_item = self.items.find((elt) => elt.id === id);
          if (res_item)
            return res_item.sellPrice

        }
        return 0;
      }
    },
    /*calculate total places on all selected tables*/
    getTablesPersons() {
      let persons = 0;
      if (this.rooms && this.rooms.length > 0) {
        let tables = [].concat.apply([], this.rooms.map((room) => room.tables));
        this.form.selected.forEach((table) => {
          persons += tables.find((t) => t.uuid == table).maxPersons;
        });
      }
      return persons;
    },
    available_items() {
      const self = this;
      return (id, menu_type) => {
        let selected_items = [];
        if (Array.isArray(self.form.items)) {
          selected_items = self.form.items.map((item) => item.id);
          let index = selected_items.indexOf(id);
          selected_items.splice(index, 1);
        }

        if (Array.isArray(self.items))
          return self.items.filter((item) => !selected_items.includes(item.id) && item.menu_type_uuid === menu_type);
        else return [];
      }
    },
    items_datatable_headers() {
      return [
        {
          text: this.$t('generic.lang_selectMenuType'),
          value: 'menu_type'
        },
        {
          text: this.$t('erp.lang_items'),
          value: 'id'
        },
        {
          text: this.$t('erp.lang_quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('generic.lang_cashier_price'),
          value: 'price'
        },
        {
          text: this.$t('erp.lang_ware_total'),
          value: 'total'
        },
        {
          text: "",
          value: 'actions',
          align: 'right'
        }
      ];
    },
    // NEW: Computed property for filtered customers
    filteredCustomers() {
      if (!this.customerSearchText) {
        return this.customers;
      }
      
      const searchTerm = this.customerSearchText.toLowerCase();
      
      return this.customers.filter(customer => {
        const fullName = (customer.full_name || '').toLowerCase();
        return fullName.includes(searchTerm);
      });
    }
  },
  data: () => {
    return {
      // ADDED: Custom autocomplete data
      customerSearchText: '',
      showCustomerDropdown: false,
      searchDebounceTimer: null,
      rooms_loader: false,
      types: [],
      selectedType: null,
      customer_loader: false,
      loading: false,
      loadData: false,
      tables: [],
      form: {
        name: "",
        customer_note: "",
        internal_note: "",
        person: 1,
        date: "",
        time: "",
        end_at: "",
        selected: [],
        resType: "",
        file_to_upload: "",
        file: "",
        items: []
      },
      Items: [],
      isLoadingCustomers: false,
      search: '',
      customers: [],
      selectedTables: [],
      show: false,
      showSelect: true,
      rooms: [],
      history: [],
      current_date: '',
      menu: false,
      menu2: false,
      tables_dialog: false,
      valid: false,
      items_loader: false,
      items: null,
      item_search: "",
      default_item_obj: {
        id: '',
        quantity: 1,
        ware_id: ''
      },
      menuTypes: [],
      menu_types_loader: false
    }
  },
  async mounted() {
    this.loadTypes();
    this.getMenuTypes();

    /**
     * set current date and time
     */
    this.current_date = new Date();
    this.current_date = this.current_date.getFullYear() + '-'
        + (((this.current_date.getMonth() + 1) < 10) ? '0' + (this.current_date.getMonth() + 1) : (this.current_date.getMonth() + 1)) + '-'
        + ((this.current_date.getDate() < 10) ? '0' + this.current_date.getDate() : this.current_date.getDate());
    this.form.date = this.default_date || this.current_date;
    this.form.time = this.default_time || (((new Date().getHours() < 10) ? '0' + new Date().getHours() : new Date().getHours()) + ':' + ((new Date().getMinutes() < 10) ? '0' + new Date().getMinutes() : new Date().getMinutes())) || "00:00";


    /**
     * load items from store
     * */
    // this.Items = await this.$store.dispatch("items/getItems"); ??????
  },

  watch: {
    can_set_end_time(val) {
      if (!val) //clear end date it this reservation type doesn't support it
        this.form.end_at = null
    },
    'form.name': function (val) {
      if (val !== this.search) {
        this.showSelect = true;
        // MODIFIED: Update customer search text
        this.customerSearchText = val;
        this.fetchCustomers(val);
      }
    },
    'form.time': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.date': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.person': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.resType': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    
    // MODIFIED: Watch for customer search text changes
    customerSearchText(val) {
      if (val === null) return;
      this.fetchCustomers(val);
    },
    
    search(val) {
      if (val === null) return;
      this.isLoadingCustomers = true;

      // Lazily load input items
      this.axios.post(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + val)
          .then((res) => {
            this.customers = [];
            this.customers = res.data.results;
          })
          .catch(err => {
            //console.log(err)
          })
          .finally(() => {
            this.isLoadingCustomers = false;
            this.show = val !== "" && val !== null && this.customers.length == 0;
          })
    },
    selectedType(val) {
      this.$refs.form.reset();
      this.selectedType = val;
      this.form.resType = val.uuid;
      this.form.date = val.date;
    }
  },
  methods: {
    // ADDED: New methods for custom autocomplete
    handleCustomerInputFocus(event) {
      this.showCustomerDropdown = true;
      this.showTouchKeyboard(event);
    },
    
    handleCustomerInputChange(value) {
      this.customerSearchText = value;
      this.search = value; // Keep the original functionality
      this.showCustomerDropdown = true;
      
      // If selected customer exists but search text changes, clear selection
      if (this.form.customer && this.form.customer.full_name !== value) {
        this.form.customer = null;
      }
    },
    
    toggleCustomerDropdown() {
      this.showCustomerDropdown = !this.showCustomerDropdown;
    },
    
    selectCustomer(customer) {
      this.form.customer = customer;
      this.customerSearchText = customer.full_name;
      this.showCustomerDropdown = false;
      this.setCustomer(customer);
    },
    
    fetchCustomers(searchTerm) {
      if (searchTerm === null) return;

      clearTimeout(this.searchDebounceTimer);

      this.searchDebounceTimer = setTimeout(()=>{
        this.isLoadingCustomers = true;

        // Fetch customers from API
        this.axios.post(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + searchTerm)
          .then((res) => {
            this.customers = [];
            this.customers = res.data.results;
          })
          .catch(err => {
            // Handle error
          })
          .finally(() => {
            this.isLoadingCustomers = false;
            this.show = searchTerm !== "" && searchTerm !== null && this.customers.length == 0;
          });
      },300)
    },
    
    // ORIGINAL METHODS
    getMenuTypes() {
      this.menu_types_loader = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.MENU.GET).then((res) => {
        this.menuTypes = res.data.menuTypes;
      }).catch((err) => {

      }).finally(() => {
        this.menu_types_loader = false;
      })
    },
    openTablesDialog() {
      if (!this.$refs.form.validate()) return;
      this.tables_dialog = true;
    },
    /**
     * convert H:m to seconds
     * */
    hmToSecondsOnly(time = '') {
      let arr = time.split(':');
      if (Array.isArray(arr) && arr.length >= 2) {
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60)
      }
      return 0;
    },
    pushItem() {

      if (!this.$refs.items_form.validate()) return;
      if (!Array.isArray(this.form.items)) this.form.items = [];


      this.form.items.unshift({
        ...this.default_item_obj
      })
    },
    removeItem(item) {
      if (!Array.isArray(this.form.items)) return;

      let index = this.form.items.indexOf(item);

      if (index > -1)
        this.form.items.splice(index, 1);
    },
    selected(tables) {
      this.tables = tables;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    /**
     *@desc  cancel reservation and back to reservations list
     * */
    cancel() {
      this.$emit('close');
      this.$emit('input', 'tab1');
    },
    setCustomer(customer) {
      if (customer === undefined || customer === null) {
        this.form.phone = "";
        this.form.email = "";
        this.form.customer_id = "";
        this.form.customer_note = "";
        this.history = [];
        return;
      }
      this.form.name = customer.full_name
      this.form.phone = customer.mobile;
      this.form.email = customer.email;
      this.form.customer_id = customer.id;
      this.form.customer_note = customer.reservation_note;
      this.loadHistory(customer.id);
    },
    /*load reservations history of selected customer*/
    loadHistory(customer_id) {
      this.customer_loader = true;
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.GET
          + '?customer_id=' + customer_id
      ).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.history = [];
          this.history = res.data.reservations;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
        this.customer_loader = false;
      })
    },
    /*prepare items to create new customers*/
    addCustomer() {
      this.form.name = this.customerSearchText || this.search;
      this.showSelect = false;
      this.form.customer_id = 0;
      this.form.phone = "";
      this.form.email = "";
      this.showCustomerDropdown = false;
    },
    loadRooms() {

      if (Number(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined && (this.form.resType !== undefined && this.form.resType !== '')) {
        this.rooms_loader = true;
        this.rooms = [];
        this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.TABLES, this.form).then((res) => {
              if (res.data.STATUS === "SUCCESS") {
                this.rooms = res.data.rooms.filter((elt) => elt.tables.length > 0);
              } else if (res.data.STATUS === "FAILED") {
                Events.$emit("showSnackbar", {
                  message: res.data.msg,
                  color: "error",
                });
              } else {

                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }
            }
        ).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }).finally(() => {
          this.rooms_loader = false;
        })
      }
    },
    /**
     * @desc load all available items in selected reservation type
     * */
    loadItems(uuid) {
      if (uuid === undefined) return;
      this.selectedType = this.types.find(type => type.uuid === uuid);
      this.items_loader = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.ITEMS.GET, {
        resType_uuid: uuid
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          this.items = res.data.items;
        } else if (res.data.status === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.items_loader = false;
      })
    },
    /**
     * @desc load all reservation types
     * */
    loadTypes() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.types = res.data.types;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {

      }).finally(() => {
        this.loadData = false;
      })
    },
    async saveReservation() {

      if ((this.selectedType && !this.selectedType.disableRequiredFields && this.selectedType.skipTable !== 1)) {
        if (this.getTablesPersons < parseInt(this.form.person)) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_selectedTablesNotEnoughFor') + " " + this.form.person + " " + this.$t('generic.lang_persons') + " " + this.getTablesPersons,
            color: "error"
          });
          return;
        }
      }
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      if (this.form.file_to_upload) {
        const file = document.querySelector('#file').files[0];
        this.form.file = await this.toBase64(file);
      }

      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.CREATE, this.form).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
          this.$refs.form.reset();

          /*back to the list of reservations*/
          if (this.show_close)
            this.cancel();
          /*notify parent component to reload reservation after creating new reservation*/
          this.$emit('getEvents');

          /*notify the parent component to close dialog*/
          this.$emit('close_create');

          //notify the parent to reload statistics header
          this.$emit('reloadStatistics');
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.selected {
  border: 3px solid blue !important;
}

/* Custom Autocomplete Styles */
.keyboard-friendly-autocomplete {
  position: relative;
  width: 100%;
}

.keyboard-autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 200;
}
</style>