<template>
<div>
  <v-dialog scrollable v-model="dialog" class="main-card mb-3 card pa-0 ma-0" max-width="1000" min-width="1000"
            persistent>
    <v-card min-width="1000" class="pa-0">
      <v-card-title class="card-title card-header-tab ">

        <div :class="[this.$vuetify.theme.dark? 'white--text' : '']"
             class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('generic.lang_reservationEdit') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right actions-icon-btn">
            <v-btn :disabled="loading || loadData" :loading="loading" class="elevation-0"
                   color="primary"
                   fab small @click="saveReservation">
              <v-icon>check</v-icon>
            </v-btn>

            <v-btn class="elevation-0" color="error"
                   fab
                   small @click="close">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>

      <v-card-text class="pb-0 pt-8">
        <v-form ref="form" lazy-validation>
          <v-row>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <BaseDateInput v-model="form.date" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData || (this.selectedType && this.selectedType.date != null)"
                            :label="this.$t('generic.lang_date')"
                            :rules="[ v => !!v]"
                            dense outlined type="date"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <BaseTimeInput v-model="form.time" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :rules="[ v => !!v , v => !!v && validTime || $t('reservation.start_date_bigger_end')]"
                            dense :label="$t('generic.lang_time')" outlined
                            @focus="showTouchKeyboard"/>
            </v-col>

            <!-- set reservation end time -->
            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" v-if="this.can_set_end_time">
              <BaseTimeInput v-model="form.end_at" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                            :disabled="this.loadData"
                            :rules="[ v => !!v , v => !!v && validTime || $t('reservation.end_date_great_start')]"
                            dense :label="$t('generic.lang_endsAt')" outlined
              />

            </v-col>
            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-select @change="loadItems($event)" v-model="form.resType" :disabled="this.loadData"
                        :items="this.types"
                        :label="this.$t('generic.lang_constReservationType')" :loading="this.loadData"
                        :rules="[ v => !!v]"
                        dense
                        item-text="name"
                        item-value="uuid"
                        outlined/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <!-- Custom Keyboard Friendly Autocomplete -->
              <div v-if="showSelect" class="keyboard-friendly-autocomplete">
                <!-- Main input field -->
                <v-text-field
                  ref="customerInput"
                  v-model="customerSearchText"
                  :label="$t('generic.lang_name')"
                  :rules="[ v => !!v]"
                  :disabled="loadData"
                  :loading="isLoadingCustomers"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  @focus="handleCustomerInputFocus"
                  @input="handleCustomerInputChange"
                  @click="toggleCustomerDropdown"
                  outlined
                  dense
                  clearable
                >
                  <!-- Add loading indicator -->
                  <template v-slot:append>
                    <v-progress-circular
                      v-if="isLoadingCustomers"
                      indeterminate
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                    <v-icon v-else @click="toggleCustomerDropdown">
                      {{ showCustomerDropdown ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                  </template>
                </v-text-field>
                
                <!-- Dropdown menu for suggestions -->
                <v-card
                  v-show="showCustomerDropdown"
                  class="keyboard-autocomplete-dropdown"
                  outlined
                  elevation="4"
                >
                  <!-- Add New Customer option -->
                  <div v-if="show" class="pa-3">
                    <v-list-item class="mt-0 pt-0 mb-0 pb-0">
                      <v-list-item-title>
                        {{ search }}
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-btn icon @click="addCustomer">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                  
                  <!-- No data message -->
                  <v-list-item v-if="filteredCustomers.length === 0">
                    <v-list-item-content>
                      <div class="text-center">
                        <span v-if="isLoadingCustomers" class="mx-auto">{{ $t('generic.loading_from_server') }}</span>
                        <span v-if="!isLoadingCustomers" class="mx-auto">{{ $t('generic.lang_no_data') }}</span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  
                  <!-- Results list -->
                  <template v-else>
                    <v-list dense>
                      <v-list-item
                        v-for="(customer, index) in filteredCustomers"
                        :key="index"
                        @click="selectCustomer(customer)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="primary--text">{{ customer.full_name }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-card>
              </div>
              
              <v-text-field v-if="!showSelect" v-model="form.name" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :disabled="this.loadData" :rules="[ v => !!v]" dense
                            label="Name" outlined
                            @focus="showTouchKeyboard"/>
            </v-col>
            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.email" :disabled="this.loadData"
                            :label="this.$t('generic.lang_emailAdresse')"
                            :rules="[ v => !!v]"
                            dense outlined
                            type="email"/>
            </v-col>

            <v-col v-if="!!this.form.customer || this.form.name.length > 0" class="pt-0 pb-0 mt-0 mb-0" cols="12"
                   sm="12">
              <v-textarea v-model="form.customer_note" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="this.loadData"
                          dense
                          :label="$t('generic.lang_customerNote')"
                          outlined @focus="showTouchKeyboard"/>
            </v-col>


            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.phone" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :label="this.$t('generic.lang_telefone')"
                            :rules="[ v => !!v]"
                            dense outlined type="tel"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
              <v-text-field v-model="form.person" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                            :label="this.$t('generic.lang_noOfPersons')"
                            :min="1" :rules="[ v => !!v]"
                            dense
                            outlined type="number"
                            @focus="showTouchKeyboard"/>
            </v-col>

            <!--start: list available items-->
            <v-col cols="12" v-if="form.resType">
              <v-expansion-panels class="elevation-0">
                <v-expansion-panel class="elevation-0">
                  <v-progress-linear indeterminate color="primary" v-if="this.items_loader"/>
                  <v-expansion-panel-header color="success" class="white--text">{{
                      this.$t("erp.lang_items")
                    }}
                  </v-expansion-panel-header>


                  <v-expansion-panel-content v-if="Array.isArray(this.items)" class="elevation-0 pt-4">
                    <div class="w-100 text-right">
                      <v-spacer/>
                      <v-btn @click="pushItem" class="mx-auto" color="primary" outlined>
                        {{ $t('generic.lang_add') }}
                      </v-btn>
                    </div>

                    <v-form lazy-validation ref="items_form">
                      <v-data-table v-if="Array.isArray(this.form.items) && this.form.items.length > 0"
                                    :items="this.form.items" :headers="this.items_datatable_headers">
                        <template v-slot:item.id="{item}">
                          <v-select @change="$forceUpdate()"
                                    :rules="[v => !!v ||  $t('generic.lang_select_an_item')]" v-model="item.id" dense
                                    outlined
                                    hide-details :items="available_items(item.id)"
                                    item-value="id"
                                    item-text="name"/>
                        </template>

                        <template v-slot:item.quantity="{item}">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" :min="1"
                                        type="number" v-model="item.quantity" dense outlined hide-details
                                        item-value="id"
                                        item-text="name"/>
                        </template>

                        <template v-slot:item.price="{item}">
                          {{ itemPrice(item.id) | currency }}
                        </template>


                        <template v-slot:item.total="{item}">
                          {{ (itemPrice(item.id) * item.quantity) | currency }}
                        </template>


                        <template v-slot:item.actions="{item}">
                          <v-btn @click="removeItem(item)" icon color="error">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-col>
            <!--end: list available items-->


            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-textarea v-model="form.note" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loadData"
                          :label="$t('generic.lang_online') + ' ' + this.$t('generic.lang_notes')" outlined
                          @focus="showTouchKeyboard"/>
            </v-col>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-textarea v-model="form.internal_note" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="this.loadData"
                          :label="this.$t('generic.lang_internalNotes')"
                          outlined
                          @focus="showTouchKeyboard"/>
            </v-col>
            <v-col cols="12">
              <v-text-field id="file" v-model="form.file_to_upload" dense outlined type="file"/>
            </v-col>

            <v-col
                v-if="!(parseInt(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined && (this.form.resType !== undefined && this.form.resType !== '') )"
                cols="12">
              <v-progress-linear v-if="this.rooms_loader" color="primary" indeterminate>

              </v-progress-linear>
              <v-alert class="mx-auto" type="info">
                {{ $t('generic.lang_reserverationMissingDateTime') }}
              </v-alert>
            </v-col>
            <v-col
                v-if="parseInt(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined  && (this.form.resType !== undefined && this.form.resType !== '')"
                class="pa-0"
                cols="12">
              <v-progress-linear v-if="this.rooms_loader" color="primary" height="30" indeterminate>
                {{ $t('generic.loading_availableTables') }} ...
              </v-progress-linear>
              <v-expansion-panels accordion active-class="grey lighten-3" class="elevation-0" focusable>
                <v-expansion-panel
                    v-for="(item,i) in this.rooms"
                    :key="i"
                    class="elevation-0"
                    :class="[$vuetify.theme.dark? 'dark-bg' : '']"
                >
                  <v-expansion-panel-header :class="[$vuetify.theme.dark? 'dark-bg' : '']">{{
                      item.room_name
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content :class="[$vuetify.theme.dark? 'dark-bg' : '']">

                    <v-item-group v-model="form.selected" multiple>
                      <v-container>
                        <v-row>
                          <v-col
                              v-for="table  in item.tables"
                              :key="table.uuid"
                              class="pa-2"
                              cols="12"
                              lg="4"
                              md="4"
                              sm="6"
                              xl="3"
                          >
                            <v-item v-slot:default="{ active, toggle }"
                                    :value="table.uuid">
                              <v-card
                                  class="d-flex align-center"
                                  color="elevation-0 transparent"

                              >
                                <v-scroll-y-transition>
                                  <div

                                      class="flex-grow-1 text-center"
                                  >
                                    <v-badge
                                        v-if="(active || form.selected.includes(table.uuid)) || (active && form.selected.includes(table.uuid))"
                                        avatar
                                        overlap
                                    >

                                      <template v-slot:badge>
                                        <v-icon color="white" small>
                                          check
                                        </v-icon>
                                      </template>

                                      <v-avatar
                                          :class="table.color + ' white--text mx-auto ' + (active? 'border border-primary' : '')"
                                          :tile="table.type === 'rect'"
                                          size="128"
                                          style="cursor: pointer !important;"
                                          v-bind:style="{
                                          'background-color':  table.color
                                        }"
                                          @click="toggle">
                                        {{ table.name }}
                                      </v-avatar>
                                    </v-badge>

                                    <v-avatar
                                        v-if="!active"
                                        :class="table.color + 'white--text mx-auto'"
                                        :tile="table.type === 'rect'"
                                        size="128"
                                        style="cursor: pointer !important;"
                                        v-bind:style="{
                                          'background-color':  table.color
                                        }"
                                        @click="toggle">
                                      {{ table.name }}
                                    </v-avatar>
                                  </div>
                                </v-scroll-y-transition>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>


          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
    <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                        :accept="hideTouchKeyboard"
                        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                        :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                        :options="touchKeyboard.options" class="internalWidthExpanded"/>
  </div>
</div>
</template>

<script>

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

export default {
  name: "EditReservation",
  props: {
    default_data: Object,
    dialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixin],
  components: {BaseDateInput, BaseTimeInput},
  data: () => {
    return {
      // ADDED: Custom autocomplete data
      customerSearchText: '',
      showCustomerDropdown: false,
      
      rooms_loader: false,
      types: [],
      loading: false,
      loadData: false,
      selectedType: null,
      form: {
        name: "",
        customer_note: "",
        internal_note: "",
        person: 1,
        date: "",
        time: "",
        selected: [],
        resType: "",
        file_to_upload: "",
        file: "",
        end_at: ""
      },
      isLoadingCustomers: false,
      search: '',
      customers: [],
      show: false,
      showSelect: true,
      rooms: [],
      items_loader: false,
      items: null,
      item_search: "",
      default_item_obj: {
        id: '',
        quantity: 1,
        ware_id: ''
      }
    }
  },
  mounted() {
    this.loadTypes();
    this.form = this.default_data;
    this.form.customer = {
      id: this.default_data.customer_id
    };
    
    // Set the customer search text to match the name
    this.customerSearchText = this.default_data.name;

    this.axios.post(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + this.default_data.name)
        .then((res) => {
          this.customers = [];
          this.customers = res.data.results;
          this.form.selected = this.form.tables.map((elt) => elt.uuid);
        })
        .catch(err => {
          //console.log(err)
        })
        .finally(() => {
          this.isLoadingCustomers = false;
        })
  },

  computed: {
    // NEW: Computed property for filtered customers
    filteredCustomers() {
      if (!this.customerSearchText) {
        return this.customers;
      }
      
      const searchTerm = this.customerSearchText.toLowerCase();
      
      return this.customers.filter(customer => {
        const fullName = (customer.full_name || '').toLowerCase();
        return fullName.includes(searchTerm);
      });
    },
    
    itemPrice() {
      const self = this;

      return (id) => {
        //let's find the item
        if (Array.isArray(self.items)) {
          let res_item = self.items.find((elt) => elt.id === id);
          if (res_item)
            return res_item.sellPrice

        }
        return 0;
      }
    },
    available_items() {
      const self = this;
      return (id) => {
        let selected_items = [];
        if (Array.isArray(self.form.items)) {
          selected_items = self.form.items.map((item) => item.id);
          let index = selected_items.indexOf(id);
          selected_items.splice(index, 1);
        }

        if (Array.isArray(self.items))
          return self.items.filter((item) => !selected_items.includes(item.id));
        else return [];
      }
    },
    validTime() {
      if (this.can_set_end_time) {
        if (this.form.time && this.form.end_at) {
          return this.hmToSecondsOnly(this.form.time) < this.hmToSecondsOnly(this.form.end_at)
        }
      }
      return true;
    },
    can_set_end_time() {
      if (Array.isArray(this.types) && this.form.resType) {
        //find selected type
        let index = this.types.indexOf(this.types.find((type) => type.uuid === this.form.resType));
        if (index > -1)
          return this.types[index].can_set_end_time === 1;
      }
      return false;
    },
    items_datatable_headers() {
      return [
        {
          text: this.$t('erp.lang_item'),
          value: 'id'
        },
        {
          text: this.$t('erp.lang_quantity'),
          value: 'quantity'
        },
        {
          text: this.$t('generic.lang_cashier_price'),
          value: 'price'
        },
        {
          text: this.$t('erp.lang_ware_total'),
          value: 'total'
        },
        {
          text: "",
          value: 'actions',
          align: 'right'
        }
      ];
    }
  },
  watch: {
    can_set_end_time(val) {
      if (!val) //clear end date it this reservation type doesn't support it
        this.form.end_at = null
    },
    dialog(val) {
      this.$emit('input', val);
    },
    'form.name': function (val) {
      if (val !== this.search) {
        this.showSelect = true;
        this.customerSearchText = val;
        this.fetchCustomers(val);
      }
    },
    'form.time': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.date': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.person': function () {
      this.form.selected = [];
      this.loadRooms();
    },
    'form.resType': function (val) {
      this.form.selected = [];
      if (!this.items_loader && this.selectedType == null)
        this.loadItems(val, false);
      this.loadRooms();
    },
    
    // MODIFIED: Watch for customer search text changes
    customerSearchText(val) {
      if (val === null) return;
      this.fetchCustomers(val);
    },
    
    search(val) {
      if (val === null) return;
      this.isLoadingCustomers = true;

      // Lazily load input items
      this.axios.post(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + val)
          .then((res) => {
            this.customers = [];
            this.customers = res.data.results;
          })
          .catch(err => {
            //console.log(err)
          })
          .finally(() => {
            this.isLoadingCustomers = false;
            this.show = val !== "" && val !== null && this.customers.length == 0;
          })
    },
    selectedType(val) {
      this.form.date = val.date;
    }
  },
  methods: {
    // ADDED: New methods for custom autocomplete
    handleCustomerInputFocus(event) {
      this.showCustomerDropdown = true;
      this.showTouchKeyboard(event);
    },
    
    handleCustomerInputChange(value) {
      this.customerSearchText = value;
      this.search = value; // Keep the original functionality
      this.showCustomerDropdown = true;
      
      // If selected customer exists but search text changes, clear selection
      if (this.form.customer && this.form.customer.full_name !== value) {
        this.form.customer = null;
      }
    },
    
    toggleCustomerDropdown() {
      this.showCustomerDropdown = !this.showCustomerDropdown;
    },
    
    selectCustomer(customer) {
      this.form.customer = customer;
      this.customerSearchText = customer.full_name;
      this.showCustomerDropdown = false;
      this.setCustomer(customer);
    },
    
    fetchCustomers(searchTerm) {
      if (searchTerm === null) return;
      this.isLoadingCustomers = true;

      // Fetch customers from API
      this.axios.post(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + searchTerm)
        .then((res) => {
          this.customers = [];
          this.customers = res.data.results;
        })
        .catch(err => {
          // Handle error
        })
        .finally(() => {
          this.isLoadingCustomers = false;
          this.show = searchTerm !== "" && searchTerm !== null && this.customers.length == 0;
        });
    },
    
    pushItem() {
      if (!this.$refs.items_form.validate()) return;
      if (!Array.isArray(this.form.items)) this.form.items = [];


      this.form.items.unshift({
        ...this.default_item_obj
      })
    },
    removeItem(item) {
      if (!Array.isArray(this.form.items)) return;

      let index = this.form.items.indexOf(item);

      if (index > -1)
        this.form.items.splice(index, 1);
    },
    /**
     * convert H:m to seconds
     * */
    hmToSecondsOnly(time = '') {
      let arr = time.split(':');
      if (Array.isArray(arr) && arr.length >= 2) {
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60)
      }
      return 0;
    },
    /**
     * @desc load all available items in selected reservation type
     * */
    loadItems(uuid, isSelectedType = true) {
      if (uuid === undefined) return;

      if (isSelectedType) {
        this.selectedType = this.types.find(type => type.uuid === uuid);
      }
      
      this.items_loader = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.ITEMS.GET, {
        resType_uuid: uuid
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          this.items = res.data.items;
        } else if (res.data.status === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.items_loader = false;
      })
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    loadRooms() {
      if (parseInt(this.form.person) > 0 && this.form.date !== undefined && this.form.time !== undefined && (this.form.resType !== undefined && this.form.resType !== '')) {
        this.rooms_loader = true;
        this.rooms = [];
        this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.TABLES + '?is_edit=' + true, this.form).then((res) => {
              if (res.data.STATUS === "SUCCESS") {
                this.rooms = res.data.rooms.filter((elt) => elt.tables.length > 0);
              } else if (res.data.STATUS === "FAILED") {
                Events.$emit("showSnackbar", {
                  message: res.data.msg,
                  color: "error",
                });
              } else {

                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }
            }
        ).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }).finally(() => {
          this.rooms_loader = false;
        })
      }
    },
    close() {
      this.$emit('input', false);
    },
    setCustomer(customer) {
      this.form.phone = customer.mobile;
      this.form.email = customer.email;
      this.form.customer_id = customer.id;
      this.form.customer_note = "";
    },
    /**
     * function to create new user if the user not found in our customers list
     */
    addCustomer() {
      this.form.customer = null;
      this.form.name = this.customerSearchText || this.search;
      this.showSelect = false;
      this.form.customer_id = 0;
      this.form.phone = "";
      this.form.email = "";

      this.form.customer_note = "";
      this.showCustomerDropdown = false;

      /**
       * we'll use this property to create new user if the current one is changed
       *
       *  */
      this.form.customer_changed = true;
    },
    loadTypes() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.types = res.data.types;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {

      }).finally(() => {
        this.loadData = false;
      })
    },
    async saveReservation() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      if (this.form.file_to_upload) {
        const file = document.querySelector('#file').files[0];
        this.form.file = await this.toBase64(file);
      }

      /**
       * delete empty values
       */
      for (let elt in this.form) {
        if (this.form[elt] == null)
          delete this.form[elt];
      }
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.UPDATE,
          {
            uuid: this.form.uuid,
            file: this.form.file,
            date: this.form.date,
            time: this.form.time,
            person: this.form.person,
            customer_id: this.form.customer_id,
            customer: this.form.customer,
            customer_note: this.form.customer_note,
            email: this.form.email,
            phone: this.form.phone,
            end_at: this.form.end_at,
            note: this.form.note,
            resType: this.form.resType,
            internal_note: this.form.internal_note,
            confirmed: this.form.confirmed,
            selected: this.form.selected,
            canceled: this.form.canceled,
            customer_changed: this.form.customer_changed,
            items: this.form.items
          }
      ).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
          this.$refs.form.reset();

          this.$emit('getEvents');
          this.$emit('close_edit')
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.selected {
  border: 3px solid blue !important;
}

/* Custom Autocomplete Styles */
.keyboard-friendly-autocomplete {
  position: relative;
  width: 100%;
}

.keyboard-autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 200;
}
</style>